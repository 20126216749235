const CKEditorConfig = {
  data() {
    return {
      editorConfig: {
        toolbar: [
          "bold",
          "italic",
          "|",
          "link",
          "|",
          "NumberedList",
          "BulletedList",
          "Blockquote",
          "|",
          "Undo",
          "Redo",
          "|",
          "Heading",
        ],

        language: "fr",
      },
    };
  },
};

export default CKEditorConfig;
